import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate  } from "react-router-dom";
import { IMG_SUCCESS, IMG_SAD_FACE, IMG_HAPPY_FACE } from "../../helper/Config";
import { StoreContext } from "../../context/StoreProvider";

const ColourVisionCheckSuccess = () => {
    const history = useNavigate();
    const { colorVisionCtx } = useContext(StoreContext);
    const { colorVisionData } = colorVisionCtx;
    return (
        <div className="container margin-auto text-center success-container">
            <h4>Result</h4>
            <div className="success-img-container">
                <img src={IMG_SUCCESS} alt={'Success'} />
                <p>Congratulations on completing the test</p>
            </div>
            <div className="success-img-result">
                <img src={colorVisionData.left ? IMG_HAPPY_FACE : IMG_SAD_FACE} alt={'Result'} />
                <img src={colorVisionData.right ? IMG_HAPPY_FACE : IMG_SAD_FACE} alt={'Result'} />
            </div>
            {
                colorVisionData.left && colorVisionData.right ? <h4>Your {colorVisionData.title} seems to be excellent</h4> : <h4>Your {colorVisionData.title} seems to be reduced</h4>
            }
            <div className="success-action">
                <Button onClick={ () => history('/send-email')}>Email Test Result</Button>
                <Button onClick={ () => history('/astigmatism-check')}>Continue to Astigmatism</Button>
            </div>
            <div>
                <p className="f14">For further consultation, we recommend that you visit a professional optician</p>
            </div>
        </div>
    )
}

export default ColourVisionCheckSuccess;