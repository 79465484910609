import React from "react";
import { Button } from "react-bootstrap";

const ColourVisionTest = ({ A, Q, handleNext }) => {
    return <div className="row justify-content-md-center flex-row acuity-test img">
        <div className="col-md-12 text-center">
            <img src={Q} alt={1} />
        </div>
        <div className="colour-vision d-flex justify-content-md-center">
            {
                A.qus.map(obj => <Button key={obj} value={obj} onClick={ obj => handleNext(obj)}>{obj}</Button>)
            }
        </div>
    </div>
}

export default ColourVisionTest;