import React, { Fragment, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import CardImage from '../../assets/images/credit-card.png';

const CreditCard = () => {
    const [cardZoom, setZoom] = useState(60);
    return <Fragment>
        <Container className="d-flex flex-column align-items-center">
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <img src={CardImage} alt="Credit Card" style={{transform: `scale(${cardZoom/100})`}} />
                    <input
                        type="range"
                        className="form-range"
                        id="customRange1"
                        min="40"
                        max="100"
                        value={cardZoom}
                        onChange={e => setZoom(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="credit-card-btn">Done</Button>
                </Col>
            </Row>
        </Container>
    </Fragment>
}

export default CreditCard;