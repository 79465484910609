import React, { Fragment } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import WearYourGlass from "../../assets/images/were-your-glass.png";
import InstructionVisualAcuity from "../../assets/images/instruction-for-visual-acuity.png";
import KeepDeviceClose from "../../assets/images/keep-your-device-close.png";
import KeepLeftEyeClose from "../../assets/images/keep-left-eye-close.png";
import BrightnessImg from "../../assets/images/screen-brightness.png";
import { Button } from "react-bootstrap";

const Instructions = () => {
    const history = useNavigate();
    return <Fragment>
        <div className="container">
            <div className="row justify-content-md-center">
                <Carousel className="col-md-6 col-xs-12 instruction-carousel" variant="dark">
                    <Carousel.Item>
                        <div>
                            <p>Online Eye Test</p>
                            <img src={KeepLeftEyeClose} alt={'Keep left eye close'} />
                        </div>
                        <Carousel.Caption>
                            <p>Cover your left eye</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <p>Online Eye Test for Visual Acuity</p>
                            <img src={BrightnessImg} alt={'Wear your glass'} />
                        </div>
                        <Carousel.Caption>
                            <p>Maximize the brightness of your screen in order to get accurate result.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <p>Online Eye Test</p>
                            <img src={WearYourGlass} alt={'Wear your glass'} />
                        </div>
                        <Carousel.Caption>
                            <p>Wear your glass or contacts (if you have any).</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <p>Online Eye Test for Visual Acuity</p>
                            <img src={KeepDeviceClose} alt={'Keep your device close'} />
                        </div>
                        <Carousel.Caption>
                            <p>Keep your device at arm's length during the check</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <p>Online Eye Test for Visual Acuity</p>
                            <img src={InstructionVisualAcuity} alt={'Instruction for visual acuity'} />
                        </div>
                        <Carousel.Caption>
                            <p style={{position: 'relative', top: 20}}>Using the top ring as reference, mark the correlating gap on the lower ring.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-12 col-xs-12 my-5 text-center">
                    <Button onClick={ () => history('/acuity-check')}>Next</Button>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Instructions;