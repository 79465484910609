import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import ResultPage from './pages/ResultPage.react';
import CreditCard from './pages/calibrate/CreditCard.react';
import Instructions from './pages/instructions/Instructions.react';
import AcuityCheck from './pages/acuity/AcuityCheck.react';
import AcuityCheckContainer from './pages/acuity/AcuityCheckContainer.react';
import AcuityCheckSuccess from './pages/acuity/AcuityCheckSuccess.react';
import ContrastCheckContainer from './pages/contrast/ContrastCheckContainer.react';
import ContrastCheck from './pages/contrast/ContrastCheck.react';
import ContrastCheckSuccess from './pages/contrast/ContrastCheckSuccess.react';
import ColourVisionCheck from './pages/colour-vision/ColourVisionCheck.react';
import ColourVisionCheckSuccess from './pages/colour-vision/ColourVisionCheckSuccess.react';
import ColourVisionCheckContainer from './pages/colour-vision/ColourVisionCheckContainer.react';
import AstigmatismCheckContainer from './pages/astigmatism/AstigmatismCheckContainer.react';
import AstigmatismCheckSuccess from './pages/astigmatism/AstigmatismCheckSuccess.react';
import VisualFieldCheckContainer from './pages/visual-field/VisualFieldCheckContainer.react';
import VisualFieldCheck from './pages/visual-field/VisualFieldCheck.react';
import VisualFieldCheckSuccess from './pages/visual-field/VisualFieldCheckSuccess.react';
import AstigmatismCheck from './pages/astigmatism/AstigmatismCheck.react';
import SendEmail from './pages/SendEmail.react';

function App() {
  return (
    <div className="App">
      <Container className="fill d-flex justify-content-center align-items-center">
        <Router>
          <Routes>
            
            <Route path="/" element={<Instructions />} />
            <Route path="/credit-card" element={<CreditCard />} />

            {/* <Route path="/acuity-check-instructions" element={<Instructions />} /> */}
            <Route exact path="/result" element={<ResultPage />} />
            <Route exact path="/send-email" element={<SendEmail />} />

            <Route path="/acuity-check" element={<AcuityCheckContainer />} />
            <Route path="/acuity-check-test" element={<AcuityCheck />} />
            <Route path="/acuity-check-success" element={<AcuityCheckSuccess />} />

            <Route path="/contrast-check" element={<ContrastCheckContainer />} />
            <Route path="/contrast-check-test" element={<ContrastCheck />} />
            <Route path="/contrast-check-success" element={<ContrastCheckSuccess />} />

            <Route path="/colour-vision-check" element={<ColourVisionCheckContainer />} />
            <Route path="/colour-vision-check-test" element={<ColourVisionCheck />} />
            <Route path="/colour-vision-check-success" element={<ColourVisionCheckSuccess />} />

            <Route path="/astigmatism-check" element={<AstigmatismCheckContainer />} />
            <Route path="/astigmatism-check-test" element={<AstigmatismCheck />} />
            <Route path="/astigmatism-check-success" element={<AstigmatismCheckSuccess />} />

            <Route path="/visual-field-check" element={<VisualFieldCheckContainer />} />
            <Route path="/visual-field-check-test" element={<VisualFieldCheck />} />
            <Route path="/visual-field-check-success" element={<VisualFieldCheckSuccess />} />

          </Routes>
        </Router>
      </Container>
    </div>
  );
}

export default App;
