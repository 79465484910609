import React, { Fragment, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate  } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { StoreContext } from "../context/StoreProvider";
import { DEFAULT_STORE_DATA, IMG_HAPPY_FACE, IMG_SAD_FACE } from "../helper/Config";

const LandingPage = () => {
    const history = useNavigate();
    const { ...store } = useContext(StoreContext);
    const startAgain = () => {
        Object.keys(store).forEach((item, index) => {
            const Objdata = Object.keys(store[item])[1];
            const setData = store[item][Objdata];
            setData(DEFAULT_STORE_DATA[index]);
        });
        history('/');
    }
    return <Fragment>
        <div className="container">
            <div className="row justify-content-center d-flex">
                <div className="col-md-9 col-xs-12">
                <Accordion className="result-acc" defaultActiveKey="0">
                    {
                        Object.keys(store).map((item, index) => {
                            const Objdata = Object.keys(store[item])[0];
                            const data = store[item][Objdata];
                            return <Accordion.Item key={index} eventKey={index}>
                            <Accordion.Header className="">
                                <div className="d-flex justify-content-between w-100 align-items-center">
                                    <h4>{data.title}</h4>
                                    <img className="px-2" src={data.left && data.right ? IMG_HAPPY_FACE : IMG_SAD_FACE} alt={data.title} />
                                </div>
                            </Accordion.Header>
                        <Accordion.Body className="text-center">
                            {
                                data.left && data.right ? <h4>Your {data.title} seems to be excellent</h4> : <h4>Your {data.title} seems to be reduced</h4>
                            }
                            <div className="success-img-result">
                                <img src={data.left ? IMG_HAPPY_FACE : IMG_SAD_FACE} alt={'Result'} />
                                <img src={data.right ? IMG_HAPPY_FACE : IMG_SAD_FACE} alt={'Result'} />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                        })
                    }
                </Accordion>
                </div>
                <div className="d-flex justify-content-md-center end-section success-action">
                    <Button onClick={ () => history('/send-email')}>Email Test Result</Button>
                    <Button onClick={startAgain}>Start Again</Button>
                </div>
            </div>
        </div>
    </Fragment>
}

export default LandingPage;