import React, { useState, useEffect } from "react";
import { IMG_FAIL, IMG_SUCCESS, INTERACTIVE_IMG } from "../../helper/Config";

const AcuityTest = ({ A, Q, updateAns }) => {
    const [selectedPath, setPath] = useState(null);
    const [isRightAns, setIsRightAns] = useState(null);
    const handleClick = e => {
        if(selectedPath !== null) {
            e.preventDefault();
        } else {
            let flag = false;
            if(e.target.id === A) {
                flag = true;
                
            }
            setIsRightAns(flag);
            setPath(e.target.id);
            updateAns(flag);
        }
    }
    useEffect(() => {
        setInterval(() => {
            setPath(null);
            setIsRightAns(null);
        }, 1000)
    }, [selectedPath])

    return <div className="row justify-content-md-center flex-row acuity-test img text-center">
        <div className="col-md-12 test-image">
            <img src={Q} alt={1} />
        </div>
        <div className="col-md-12">
            {isRightAns && <img src={IMG_SUCCESS} alt="success" className="ans-success" />}
            {isRightAns === false && <img src={IMG_FAIL} alt="fail" className="ans-success" />}
            <svg xmlns="http://www.w3.org/2000/svg" width="260.002" height="260.002" className="controls ng-tns-c130-3 ng-trigger ng-trigger-controlAnimations" style={{fill: 'rgb(0, 0, 0)', opacity: 1}}>
                {
                    Object.keys(INTERACTIVE_IMG).map(p => {
                        return selectedPath !== p && <g key={p} onClick={handleClick}>{INTERACTIVE_IMG[p]}</g>
                    })
                }
            </svg>
        </div>
    </div>
}

export default AcuityTest;