import React, { useState } from 'react';
import { ACUITY_TEST, CONTRAST_TEST, COLOR_VISION_TEST, ASTIGMATISM_TEST, VISION_FIELD_TEST } from '../helper/Config';

export const StoreContext = React.createContext(null);

const StoreProvider = ({ children }) => {
  const [acuityData, setAcuityData] = useState({ ...ACUITY_TEST });
  const [contrastData, setContrast] = useState({ ...CONTRAST_TEST });
  const [colorVisionData, setColorVision] = useState({ ...COLOR_VISION_TEST });
  const [astigmationData, setAstigmation] = useState({ ...ASTIGMATISM_TEST });
  const [visionFieldData, setVisionField] = useState({ ...VISION_FIELD_TEST });

  const store = {
    acuityCtx: {acuityData, setAcuityData},
    contrastCtx: {contrastData, setContrast},
    colorVisionCtx: {colorVisionData, setColorVision},
    astigmationCtx: {astigmationData, setAstigmation},
    visionFieldCtx: {visionFieldData, setVisionField},
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider;