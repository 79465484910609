import TestQ1 from "../assets/images/visualt-acuity/test-01.png";
import TestQ2 from "../assets/images/visualt-acuity/test-02.png";
import TestQ3 from "../assets/images/visualt-acuity/test-03.png";
import TestQ4 from "../assets/images/visualt-acuity/test-04.png";
import TestQ5 from "../assets/images/visualt-acuity/test-05.png";

import Contrast1Img from "../assets/images/contrast/test-01.png";
import Contrast2Img from "../assets/images/contrast/test-02.png";
import Contrast3Img from "../assets/images/contrast/test-03.png";
import Contrast4Img from "../assets/images/contrast/test-04.png";
import Contrast5Img from "../assets/images/contrast/test-05.png";
import Contrast6Img from "../assets/images/contrast/test-06.png";

import ColorVision1Img from "../assets/images/colour vision/test-01.png";
import ColorVision2Img from "../assets/images/colour vision/test-02.png";
import ColorVision3Img from "../assets/images/colour vision/test-03.png";
import ColorVision4Img from "../assets/images/colour vision/test-04.png";
import ColorVision5Img from "../assets/images/colour vision/test-05.png";

import AstigmatismImg from "../assets/images/astigmatism/test-01.png";
import VisualField from "../assets/images/visual-field/test-01.png";

import SUCCESS_IMG from "../assets/images/success.png";
import FAIL_IMG from "../assets/images/fail.png";
import HAPPY_EYE from "../assets/images/happy-eye.png";
import SAD_EYE from "../assets/images/sad-eye.png";
import MAIL_IMG from "../assets/images/mail.png";
import HAPPY_IMG from "../assets/images/happy.png";
import SAD_IMG from "../assets/images/sad.png";

export const ACUITY = 'ACUITY';
export const CONTRAST = 'CONTRAST';
export const COLOR_VISION = 'COLOR_VISION';
export const ASTIGMATISM = 'ASTIGMATISM';
export const VISION_FIELD = 'VISION_FIELD';
export const EYE = 'eye';
export const DEFAULT_EYE = 'LEFT';
export const DEFAULT_TEST_OBJ = {
    'eye': DEFAULT_EYE,
    'left': null,
    'right': null
}

export const ACUITY_TEST = { ...DEFAULT_TEST_OBJ, title: 'Visual Acuity' };
export const CONTRAST_TEST = { ...DEFAULT_TEST_OBJ, title: 'Contrast Vision' };
export const COLOR_VISION_TEST = { ...DEFAULT_TEST_OBJ, title: 'Colour Vision' };
export const ASTIGMATISM_TEST = { ...DEFAULT_TEST_OBJ, title: 'Astigmatism' };
export const VISION_FIELD_TEST = { ...DEFAULT_TEST_OBJ, title: 'Visual Field' };

export const AcuityTestA = [
    'TOP',
    'RIGHT',
    'TOPLEFT',
    'BOTTOM',
    'TOPRIGHT',
    'BOTTOMLEFT',
    'LEFT'
]

export const AcuityTestQ = [
    TestQ1,
    TestQ2,
    TestQ3,
    TestQ4,
    TestQ5
];

export const ContrastTestQ = [
    Contrast1Img,
    Contrast2Img,
    Contrast3Img,
    Contrast4Img,
    Contrast5Img,
    Contrast6Img
]

export const ContrastTestA = [
    'RIGHT',
    'TOP',
    'TOPLEFT',
    'BOTTOMRIGHT',
    'RIGHT',
    'BOTTOM',
]

export const ColourVisionQ = [
    ColorVision1Img,
    ColorVision2Img,
    ColorVision3Img,
    ColorVision4Img,
    ColorVision5Img
]

export const ColourVisionA = [
    {
        'ans': 16,
        'qus': [16, 27, 7, 'Nothing'],
        'isTrue': false
    },
    {
        'ans': 12,
        'qus': [45, 18, 12, 'Nothing'],
        'isTrue': false
    },
    {
        'ans': 8,
        'qus': [25, 8, 55, 'Nothing'],
        'isTrue': false
    },
    {
        'ans': 7,
        'qus': [7, 62, 55, 'Nothing'],
        'isTrue': false
    },
    {
        'ans': 7,
        'qus': [30, 8, 7, 'Nothing'],
        'isTrue': false
    },
]

export const Astigmatism_Img = AstigmatismImg;
export const VisualField_Img = VisualField;

export const IMG_SUCCESS = SUCCESS_IMG;
export const IMG_MAIL = MAIL_IMG;
export const IMG_FAIL = FAIL_IMG;
export const IMG_HAPPY = HAPPY_EYE;
export const IMG_SAD = SAD_EYE;

export const IMG_HAPPY_FACE = HAPPY_IMG;
export const IMG_SAD_FACE = SAD_IMG;

export const DEFAULT_STORE_DATA = [ACUITY_TEST, CONTRAST_TEST, COLOR_VISION_TEST, ASTIGMATISM_TEST, VISION_FIELD_TEST];

export const INTERACTIVE_IMG = {
    TOP: <path id="TOP" d="M158.934 57.545a77.47 77.47 0 00-13.211-3.957 78.642 78.642 0 00-31.437 0 77.47 77.47 0 00-13.211 3.957l-19.9-48.059a130.162 130.162 0 0197.674 0z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    TOPRIGHT: <path id="TOPRIGHT" d="M201.693 99.226a78.216 78.216 0 00-40.914-40.914l19.906-48.059a130.414 130.414 0 0169.066 69.066l-48.059 19.907z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    RIGHT: <path id="RIGHT" d="M202.459 158.933a77.41 77.41 0 003.959-13.213 78.785 78.785 0 000-31.437 77.477 77.477 0 00-3.957-13.209l48.057-19.9a129.319 129.319 0 016.844 22.635 130.2 130.2 0 01-6.844 75.036z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    BOTTOMRIGHT: <path id="BOTTOMRIGHT" d="M160.779 201.692a78.193 78.193 0 0024.379-16.535 78.8 78.8 0 009.525-11.545 77.993 77.993 0 007.008-12.833l48.061 19.907a130.393 130.393 0 01-69.068 69.064z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    BOTTOM: <path id="BOTTOM" d="M130 260.001a130.826 130.826 0 01-26.2-2.641 129.332 129.332 0 01-22.639-6.846l19.906-48.058a77.615 77.615 0 0013.213 3.957 78.642 78.642 0 0031.438 0 77.47 77.47 0 0013.211-3.957l19.906 48.058a129.181 129.181 0 01-22.639 6.846A130.778 130.778 0 01130 260.001z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    BOTTOMLEFT: <path id="BOTTOMLEFT" d="M79.316 249.75a130.42 130.42 0 01-69.066-69.063l48.057-19.9a78.285 78.285 0 0028.084 33.9 77.645 77.645 0 0012.836 7.015z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    LEFT: <path id="LEFT" d="M9.486 178.84a130.148 130.148 0 010-97.673l48.055 19.9a78.1 78.1 0 000 57.865z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>,
    TOPLEFT: <path id="TOPLEFT" d="M10.252 79.316a130.414 130.414 0 0169.064-69.064l19.908 48.059a77.664 77.664 0 00-12.834 7.014 78.166 78.166 0 00-28.084 33.9z" className="ng-tns-c130-3 ng-trigger ng-trigger-sliceAnimation" style={{opacity: 1}}></path>
};