import React, { useState, useContext } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { StoreContext } from "../context/StoreProvider";
import { IMG_MAIL } from "../helper/Config";
const MAIL_API = '/PHPMailer/';
const SendEmail = () => {
    const [mailSent, setMailStatus] = useState('');
    const [email, setEmail] = useState('');
    const { ...store } = useContext(StoreContext);

    const sendMail = () => {
        const resp = [];
        Object.keys(store).forEach(item => {
            const Objdata = Object.keys(store[item])[0];
            const data = store[item][Objdata];
            const msg = data.left && data.right ? `Your ${data.title} seems to be excellent` : `Your ${data.title} seems to be reduced`;
            resp.push(msg)
        });
        axios.post(MAIL_API, {email, data: resp}, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            setMailStatus(response.data);
        });
    }
    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        sendMail();
    }
    return <div className="container margin-auto text-center success-container">
        <div className="row">
            <div className="col-md-12">
                <h4>Receive results in Email</h4>
                <img src={IMG_MAIL} alt="send email" />
            </div>
            <div className="col-md-4 col-xs-10 justify-content-md-center m-auto d-flex">
                <Form className="m-auto" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 my-5" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Enter email" />
                        <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={!email} >
                        Submit
                    </Button>
                </Form>
            </div>
            {
                mailSent && <p className="mt-4 fs-6">{mailSent}</p>
            }
        </div>
    </div>
}

export default SendEmail;